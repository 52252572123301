import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Make Up Day.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our monthly FREE Partner Workout is today at 9:00 & 10:00am so
bring a friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Get your team registered for the Granite Games Fall Throwdown here
at The Ville September 14th!  Only 10 spots left!  Learn more about the
Throwdown here:
 `}<a parentName="em" {...{
            "href": "https://thegranitegames.com/about-throwdowns/"
          }}>{`https://thegranitegames.com/about-throwdowns/   `}</a></em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      